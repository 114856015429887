import React, { useEffect, useState } from "react";
import CardStyles from "../../components/atoms/card/index.module.scss";
import {
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  MenuItem,

} from "@mui/material";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import { countries, currencies, languages } from 'country-data';
import ModalComponent from "../atoms/ModalComponent";


const SuperAdminSettings = () => {

  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || JSON.parse(sessionStorage.getItem("userDetails"));

  const [formData, setFormData] = useState({
    eMSPname: "Chargnex",
    email: "admin@chargnex.com",
    mobile: "9332814567",
    taxation: "CAN E23451S",
    billingAddress: "Trunexa Inc 2305 Guenette St, St-Laurent Montral Quebec H4R 2E9 Canada",
    country: "CA",
    currency: "CAD",
    language: "eng",
  });
  const [avatarSrc, setAvatarSrc] = useState("../icons/chargnex.svg"); // Initialize with default image

  const [selectedCountry, setSelectedCountry] = useState(formData.country || ''); // Use formData.country initially
  const [selectedCountryName, setSelectedCountryName] = useState(''); // To hold the full country name
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const users = [
    {
      name: 'Abhijeet Jadhav',
    },
    {
      name: 'Akshay Atkar',
    },
    {
      name: 'Aniket Waje'
    },
    {
      name: 'Shivam Narkhede'
    }
  ]

  const handleTransferEMSP = () => {
    setOpenTransferModal(false);
   }

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);

    // Get the country details using the country code
    const countryDetails = countries.all.find(country => country.alpha2 === countryCode); // Adjusting to use find()

    if (countryDetails) {
      const countryCurrency = countryDetails.currencies
        ? countryDetails.currencies[0] // Get the first currency code
        : 'N/A';
      const countryLanguage = countryDetails.languages
        ? countryDetails.languages[0] // Get the first language code
        : 'N/A';

      // Set the selected country name
      setSelectedCountryName(countryDetails.name);

      // Update formData with the selected country, currency, and language
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: countryCode,
        currency: countryCurrency,
        language: countryLanguage,
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
};

  useEffect(() => {
    // Update the selected country and name on component mount if there's an initial value
    const initialCountryDetails = countries.all.find(country => country.alpha2 === formData.country);
    if (initialCountryDetails) {
      setSelectedCountryName(initialCountryDetails.name);
    }
  }, [formData.country]);

  // Handle image upload
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarSrc(reader.result); // Update the avatar src with the uploaded image
      };
      reader.readAsDataURL(file);
    }
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = () => setOpenEditModal(true);
 const handleUpdate = () => {
   
 }
 const handleCloseEditModal = () => setOpenEditModal(false);
 const handleOpenTransferModal = () => setOpenTransferModal(true);
 const handleCloseTransferModal = () => setOpenTransferModal(false);


  return (
    <>
      <Grid
        className={CardStyles.card}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"left"}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt="flownex"
            src={avatarSrc}
            className="CPOimage"
            sx={{
              background: "black",
              width: "69px",
              height: "69px",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              '& img': {
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }
            }}
          />

          {/* Hidden input to handle image upload */}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }} // Hide the input
            id="avatar-upload"
          />

          {/* Label to trigger file input */}
          <label htmlFor="avatar-upload" style={{ cursor: 'pointer' }}>
            <PhotoCameraBackIcon />
          </label>
        </div>
        <Typography sx={{ marginLeft: "4rem" }}>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>eMSP Name :</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.eMSPname}</Typography>
          </Typography>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>Taxation:</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.taxation}</Typography>
          </Typography>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>Billing Address:</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.billingAddress}</Typography>
          </Typography>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>Country:</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{selectedCountryName}</Typography>
          </Typography>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>Currency:</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.currency}</Typography>
          </Typography>
          <Typography sx={{ display: "flex", fontSize: "1rem" }}>
            <Typography sx={{ fontWeight: "500" }}>Language:</Typography>
            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.language}</Typography>
          </Typography>
        </Typography>
        <Typography sx={{ marginLeft: "auto", display: "flex", gap: "1rem", flexDirection: "column" }} >
                <Button onClick={handleOpenEditModal} variant="outlined">Update Account Detials</Button>
                <Button onClick={handleOpenTransferModal} variant="outlined">Transfer Ownership</Button>
        </Typography>
      </Grid>

      <ModalComponent
                open={openEditModal}
                handleClose={handleCloseEditModal}
                headerText={"Edit eMSP Details"}
                handleSubmit={handleUpdate}
                submitButtonLabel={"Update eMSP Details"}
            >
                <form>
                    <TextField
                        name="eMSPname"
                        label="eMSP Name"
                        value={formData.eMSPname}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                    />
                    <TextField
                        name="email"
                        label="Email"
                        value={formData.email}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}

                    />
                    <TextField
                        name="mobile"
                        label="Mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}

                    />
  

                    <TextField
                        name="taxation"
                        label="Taxation"
                        value={formData.taxation}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}

                    />
                    <TextField
                        name="billingAddress"
                        label="Billing Address"
                        value={formData.billingAddress}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}

                    />
                    <TextField
                name="country"
                label="Country"
                select
                onChange={handleCountryChange}
                fullWidth
                sx={{ marginBottom: 2 }}
                value={selectedCountry} // Bind to selectedCountry state
            >
                {Object.values(countries.all).map((country) => (
                    <MenuItem key={country.alpha2} value={country.alpha2}>
                        {country.name}
                    </MenuItem>
                ))}
            </TextField>

            {/* Conditionally render Currency and Language fields */}
            {selectedCountry && (
                <>
                    <TextField
                        name="currency"
                        label="Currency"
                        value={formData.currency || 'N/A'}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    <TextField
                        name="language"
                        label="Language"
                        value={formData.language || 'N/A'}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </>
            )}
                </form>
            </ModalComponent>

            <ModalComponent
            open={openTransferModal}
            handleClose={handleCloseTransferModal}
            headerText={"Transfer eMSP Admin Access"}
            handleSubmit={handleOpenTransferModal}
            submitButtonLabel={"Transfer eMSP Admin Access"}
            >
                <TextField
                    name="currenteMSP"
                    label="Current eMSP"
                    value={formData.email}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                    name="neweMSP"
                    select
                    label="New eMSP"
                   
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                >
                    {users.map((user) => (
                        <MenuItem key={user.name} value={user.name}>
                            {user.name}
                        </MenuItem>
                    ))}
                </TextField>


            </ModalComponent>
           
    </>

  );
};

export default SuperAdminSettings;