"use client";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Autocomplete
} from "@mui/material";
import ButtonComponent from "../../components/atoms/button/button";
import {
  Add,
  Visibility,
  Search as SearchIcon,
  Edit,
  Delete
} from "@mui/icons-material";
import { fetchCpoAdmins, addCpoAdmin, updateCpoAdmin, deleteCpoAdmin } from "../../redux/apis/cpo";
import ModalComponent from "../../components/atoms/ModalComponent";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { message } from "antd";
import axios from "axios";

function Cpo() {
  const [evseStations, setEvseStationsData] = useState([]);
  const [CpoData, setCpoData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalStations, setTotalStations] = useState(0);
  const [totalCpos, setTotalCpos] = useState(0);
  const [modalPage, setModalPage] = useState(0); // Modal pagination
  const [modalRowsPerPage, setModalRowsPerPage] = useState(10); // Modal pagination
  const [modalTotalStations, setModalTotalStations] = useState(0); // Modal pagination
  const [loading, setLoading] = useState(false);
  const [openSiteModal, setOpenSiteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [CpoFormData, setCpoFormData] = useState({
    cpoName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    cpoCountry: ""
  });
  const [openEditModal, setOpenEditModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCpo, setSelectedCpo] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleModalChangePage = (event, newPage) => {
    setModalPage(newPage);
  };

  const handleModalChangeRowsPerPage = (event) => {
    setModalRowsPerPage(parseInt(event.target.value, 10));
    setModalPage(0);
  };

  const fetchCountries = async () => {
    try {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      setCountries(data);
    } catch (error) {
      console.error("Error fetching Countries:", error);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);


  const fetchStationsForAdmin = async (CpoId, isModal = false) => {
    setLoading(true);
    try {

      const response = await fetchEvseStationsAPI({
        page: isModal ? modalPage + 1 : page + 1,
        limit: isModal ? modalRowsPerPage : rowsPerPage,
        filter: `{ "CpoId": "${CpoId}" }`,
      });

      if (isModal) {
        setEvseStationsData(response.list || []);
        setModalTotalStations(response.totalCount || 0);
      } else {
        setEvseStationsData(response.list || []);
        setTotalStations(response.totalCount || 0);
      }
    } catch (error) {
      console.error("Error fetching Stations:", error);
    }
    finally {
      setLoading(false);
    }
  };


  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setCpoFormData({});
  };

  const handleSubmitAddAdmin = async () => {
    try {
      await addCpoAdmin({
        ...CpoFormData
      });
      setCpoFormData({});
      setOpenAddModal(false);
      message.success("Site host admin added successfully");
      fetchCpoData();
    } catch (error) {
      console.error("Error adding site host admin:", error);
      message.error("Failed to add site host admin");
    }
  };


  const fetchCpoData = async () => {
    setLoading(true);
    try {
      const response = await fetchCpoAdmins({
        search: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      });

      setCpoData(response.list || []);
      setTotalCpos(response.totalCount || 0); // Update totalCpos with totalCount from response
      console.log(response.list);
    } catch (error) {
      console.error("Error fetching site host admins:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchCpoData();
  }, [searchQuery, page, rowsPerPage]);


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenSiteModal = (CpoId) => {
    fetchStationsForAdmin(CpoId, true);
    setOpenSiteModal(true);
  };

  const handleCloseSiteModal = () => {
    setOpenSiteModal(false);
  };



  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCpoFormData({ ...CpoFormData, [name]: value });
  };


  const handleEditModal = async () => {
    try {
      await updateCpoAdmin(selectedCpo.id, CpoFormData);
      fetchCpoData();
      message.success("CPO Admin updated successfully");
    }
    catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
      setOpenEditModal(false);
    }
  }

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  }

  const handleOpenEditModal = (cpo) => {
    setSelectedCpo(cpo);
    setOpenEditModal(true);
    setCpoFormData({
      cpoName: cpo.cpoName,
      firstName: cpo.firstName,
      lastName: cpo.lastName,
      email: cpo.email,
      phoneNumber: cpo.phoneNumber,
      cpoCountry: cpo.cpoCountry
    });
  }
  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteCpoAdmin(selectedCpo.id);
      fetchCpoData();
      message.success("CPO Admin deleted successfully");
    }
    catch (error) {
      message.error(error.response.data.message);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <>
      <MainLayout>
        <Typography
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"0rem 0rem 1rem 0rem "}
        >
          <Typography>
            <TextField
              id="search"
              variant="outlined"
              hiddenLabel
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
              }}
              InputLabelProps={{ sx: { fontSize: "14px" } }}
            />
          </Typography>
          <Typography>
            <ButtonComponent
              label={
                <>
                  <Add />{" "}
                  <Typography component={"span"}>Add CPO Admin</Typography>
                </>
              }
              width={"200px"}
              height={"40px"}
              onClick={() => setOpenAddModal(true)}
            />
          </Typography>
        </Typography>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <TableContainer
              sx={{
                maxHeight: "65vh",
                overflow: "auto",
                borderRadius: "8px 8px 0 0",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                border: "1px solid #e0e0e0",
                backgroundColor: "#ffffff",
              }}
            >
              <Table sx={{ width: "100%" }}>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    "& th": {
                      border: "1px solid #e0e0e0",
                      background: "#F5F3F3",
                      fontWeight: "500",
                      color: "#333",
                    },
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Sr. No.</TableCell>
                    <TableCell align="center">CPO Name</TableCell>
                    <TableCell align="center">CPO Admin Name</TableCell>
                    <TableCell align="center">Email Id</TableCell>
                    <TableCell align="center">Mobile No.</TableCell>
                    <TableCell align="center">View EVSE Stations</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      border: "1px solid #e0e0e0",
                      color: "#555",
                    },
                    "& tr:nth-of-type(odd)": {
                      backgroundColor: "#fafafa",
                    },
                    "& tr:hover": {
                      backgroundColor: "#f1f1f1",
                    },
                  }}
                >
                  {CpoData.map((admin, index) => (
                    <TableRow key={admin.id}>
                      <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                      <TableCell align="center">{admin?.cpoName}</TableCell>
                      <TableCell align="center">
                        {admin?.firstName} {admin?.lastName}
                      </TableCell>
                      <TableCell align="center">{admin?.email}</TableCell>
                      <TableCell align="center">{admin?.phoneNumber}</TableCell>
                      <TableCell align="center">
                        <a
                          href="javascript:void(0)"
                          onClick={() => handleOpenSiteModal(admin?.id)}
                          style={{ color: "#007bff", textDecoration: "underline" }}
                        >
                          View Station
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleOpenEditModal(admin)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(admin?.id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              sx={{
                position: "sticky",
                bottom: 0,
                overflowX: "hidden",
                padding: "0 0.5rem",
                background: "#f5f3f3",
                borderTop: "1px solid #e0e0e0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0 0 8px 8px",
              }}
              count={totalCpos}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <ModalComponent open={openSiteModal} handleClose={handleCloseSiteModal} showSubmitButton={false} headerText={"View Sites"} >
              <TableContainer
                sx={{
                  maxHeight: "65vh",
                  overflow: "auto",
                  borderRadius: "8px 8px 0 0",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                  border: "1px solid #e0e0e0",
                  backgroundColor: "#ffffff",
                }}>
                <Table>
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 99,
                      "& th": {
                        border: "1px solid #e0e0e0",
                        background: "#F5F3F3",
                        fontWeight: "500",
                        color: "#333",
                      },
                      boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <TableRow>
                      <TableCell>Sr. No.</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>No of chargers</TableCell>
                      <TableCell>Site Host Operator</TableCell>
                      <TableCell>Date Of Registration</TableCell>
                      <TableCell>Created By</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td": {
                        border: "1px solid #e0e0e0",
                        color: "#555",
                      },
                      "& tr:nth-of-type(odd)": {
                        backgroundColor: "#fafafa",
                      },
                      "& tr:hover": {
                        backgroundColor: "#f1f1f1",
                      },
                    }}
                  >
                    {evseStations.map((station, index) => (
                      <TableRow key={station.id}>
                        <TableCell align="center">{index + 1}</TableCell>
                        <TableCell align="center">{station?.name}</TableCell>
                        <TableCell align="center">{station?.address}</TableCell>
                        <TableCell align="center">{station?.chargerCount}</TableCell>
                        <TableCell align="center">
                          {station?.siteHostOperatorFirstName && station?.siteHostOperatorLastName
                            ? station.siteHostOperatorFirstName + " " + station.siteHostOperatorLastName
                            : "--"}
                        </TableCell>
                        <TableCell align="center">{new Date(station?.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell align="center">{station?.createdByUserFirstName} {station?.createdByUserLastName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    overflowX: "hidden",
                    padding: "0 0.5rem",
                    background: "#f5f3f3",
                    borderTop: "1px solid #e0e0e0",
                    boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0 0 8px 8px",
                  }}
                  count={modalTotalStations}
                  rowsPerPage={modalRowsPerPage}
                  page={modalPage}
                  onPageChange={handleModalChangePage}
                  onRowsPerPageChange={handleModalChangeRowsPerPage}
                />
              </TableContainer>
            </ ModalComponent>

            <ModalComponent
              open={openAddModal}
              handleClose={handleCloseAddModal}
              headerText="Add CPO Admin"
              handleSubmit={handleSubmitAddAdmin}
              submitButtonLabel={"submit"}
            >
              {/* Form for adding a new site */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="username"
                    name="cpoName"
                    label="CPO Name"
                    value={CpoFormData.cpoName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={CpoFormData.firstName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={CpoFormData.lastName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={CpoFormData.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="mobile"
                    name="phoneNumber"
                    label="Mobile"
                    value={CpoFormData.phoneNumber}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name.common} // Use the correct field for the country name
                    isOptionEqualToValue={(option, value) => option.name.common === value.name.common} // Compare based on country name
                    onChange={(e, value) => {
                      setCpoFormData((prev) => ({
                        ...prev,
                        cpoCountry: value ? value.name.common : "", // Set the country's common name
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        name="cpoCountry"
                        variant="outlined"
                        fullWidth
                        required
                        InputProps={{ ...params.InputProps, style: { borderRadius: 8 } }}
                      />
                    )}
                  />

                </Grid>
              </Grid>
            </ModalComponent>

            <ModalComponent
              open={openEditModal}
              handleClose={handleCloseEditModal}
              headerText="Update CPO Admin"
              handleSubmit={handleEditModal}
              submitButtonLabel={"submit"}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="username"
                    name="cpoName"
                    label="CPO Name"
                    value={CpoFormData.cpoName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={CpoFormData.firstName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={CpoFormData.lastName}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={CpoFormData.email}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="mobile"
                    name="phoneNumber"
                    label="Mobile"
                    value={CpoFormData.phoneNumber}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.name.common} // Use the correct field for the country name
                    isOptionEqualToValue={(option, value) => option.name.common === value.name.common} // Compare based on country name
                    value={
                      countries.find((country) => country.name.common === CpoFormData.cpoCountry) || null
                    } // Set the current country based on formData
                    onChange={(e, value) => {
                      setCpoFormData((prev) => ({
                        ...prev,
                        cpoCountry: value ? value.name.common : "", // Set the selected country's name
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Country"
                        name="cpoCountry"
                        variant="outlined"
                        fullWidth
                        required
                        InputProps={{ ...params.InputProps, style: { borderRadius: 8 } }}
                      />
                    )}
                  />
                </Grid>

              </Grid>
            </ModalComponent>

          </>
        )}
      </MainLayout>
    </>
  );
}

export default Cpo;
