import React, { useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Select from "react-select";
import { Typography } from "@mui/material";
import ChargerLocationPinIcon from "../icons/chargerLocationPinIcon";
import { fetchChargerLocationAPI } from "../../redux/apis/chargers";
import bluepin from "./Pins/Bluepin.svg";
import blackpin from "./Pins/Blackpin.svg";

function GoogleMapComponent() {
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "14px",
  };

  const [currentLocation, setCurrentLocation] = useState({
    label: "Singapore",
    value: { lat: 1.3586, lng: 103.9899 },
  });

  const [markers, setMarkers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchChargerLocationAPI({});
        setMarkers(data[selectedOption.toLowerCase()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [selectedOption]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDDiyJAizQKuM93iXy4_2tmpSF_avRL0Cg",
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  const iconColorMapper = (label) => {
    switch (label) {
      case "Available":
        return bluepin;
      case "Busy":
        return blackpin;
      case "Offline":
        return blackpin;
      default:
        return bluepin;
    }
  };

  const formateOptionLabel = ({ label }) => (
    <Typography sx={{ display: "flex", alignItems: "center" }}>
      <ChargerLocationPinIcon width={"10"} color={iconColorMapper(label)} />
      <Typography component={"span"} marginLeft={"10px"}>
        {label}
      </Typography>
    </Typography>
  );

  const onMapLoad = (map) => {
    mapRef.current = map;
    const clusterer = new MarkerClusterer({ map });
    markers.forEach((markerData) => {
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(markerData.lat), lng: parseFloat(markerData.lng) },
        icon: {
          url: iconColorMapper(markerData.status),
          scaledSize: new window.google.maps.Size(40, 40),
        },
      });
      marker.addListener("click", () => {
        setSelectedMarker(markerData);
      });
      clusterer.addMarker(marker);
    });
  };

  return (
    <Typography width={"100%"} height={"100%"} minHeight={"400px"}>
      <Typography display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"} marginBottom={"1rem"}>
          Locations
        </Typography>
        <Typography display={"flex"}>
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={false}
            defaultValue={{
              label: "India",
              value: { lat: 19.1095129, lng: 73.0184841 },
            }}
            options={[
              { label: "India", value: { lat: 19.1095129, lng: 73.0184841 } },
              { label: "Canada", value: { lat: 45.4991597, lng: -73.7266442 } },
              { label: "Germany", value: { lat: 52.3792079, lng: 9.4318394 } },
              { label: "London", value: { lat: 51.5285262, lng: -0.2664051 } },
            ]}
            formatOptionLabel={({ label }) => <Typography>{label}</Typography>}
            value={{ label: currentLocation.label, value: currentLocation.value }}
            onChange={(option) => setCurrentLocation(option)}
          />
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            options={[
              { value: "All", label: "All" },
              { value: "Available", label: "Available" },
              { value: "Busy", label: "Busy" },
              { value: "Offline", label: "Offline" },
            ]}
            placeholder="Choose Location"
            formatOptionLabel={formateOptionLabel}
            value={{ value: selectedOption, label: selectedOption }}
            onChange={(option) => setSelectedOption(option.value)}
          />
        </Typography>
      </Typography>

      <Typography height={"90%"} width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={currentLocation.value}
          onLoad={onMapLoad}
        >
          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.lat),
                lng: parseFloat(selectedMarker.lng),
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <h2>{selectedMarker.chargeBoxId}</h2>
                <p>Status: {selectedMarker.status}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </Typography>
    </Typography>
  );
}

export default GoogleMapComponent;
