import * as React from "react";
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { WhoAmI } from "../../redux/apis/user";
import { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { json } from "react-router";

const ProfileMenu = ({ name, role, avatarUrl, onLogout, onMyAccountClick }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const userId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  const [profilePicture, setProfilePictureUpdated] = useState("");

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    onLogout();
  };

  const handleMyAccountClick = () => {
    handleMenuClose();
    onMyAccountClick();
  };

  const [userData, setUserData] = useState({
    file: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    category: "",
  });

  const userDetails = JSON.parse(localStorage.getItem(`userDetails`) || sessionStorage.getItem(`userDetails`));
  const userRole = userDetails?.category;

  const cpoDetails = (userRole === "CPO Admin") ? JSON.parse(localStorage.getItem(`cpoDetails`) || sessionStorage.getItem(`cpoDetails`)) : false;

  const fetchCpoDetails = async () => {
    try {
      const response = await WhoAmI();
      if (response) {
        setUserData({
          file: response?.user?.profilePicture || "",
          firstName: response?.user?.firstName || "",
          lastName: response?.user?.lastName || "",
          email: response?.user?.email || "",
          phoneNumber: response?.user.phoneNumber || "N/A",
          category: response?.userRole || "",
        });
            // Reset profile picture update flag
            setProfilePictureUpdated(response?.user?.profilePicture);
      }
    } catch (error) {
      console.error("Error fetching CPO details:", error);
    }
  };

  //Initial  Fetch
  useEffect(() => {
    fetchCpoDetails();
  }, []);

  // Fetch on Profile Picture Update
  useEffect(() => {
    if (profilePicture) {
      fetchCpoDetails();
    }
  }, []);


  useEffect(() => {
    // Check if the userRole is "CPO Admin" and required details are available
    if (userRole !== "CPO Admin" || !cpoDetails?.id || !userId) return;
  
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });
  
    // Subscribe to the channel
    const channel = pusher.subscribe(cpoDetails.id);
  
    // Bind to the event
    channel.bind(`profile-updated-${userId}`, (data) => {
      setProfilePictureUpdated(data?.profilePicture || "");
    });
  
    // Clean up subscription on component unmount
    return () => {
      channel.unbind(`profile-updated-${userId}`);
      channel.unsubscribe();
    };
  }, [userRole, cpoDetails, userId]); // Added userRole to dependencies
  
  


  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body2" fontSize={16} fontWeight={"500"}>
          {userData?.firstName + " " + userData?.lastName}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          fontSize={14}
          textAlign={"right"}
        >
          {userData?.category}
        </Typography>
      </Box>
      <Tooltip title="Profile">
        <IconButton onClick={handleMenuOpen}>
          <Avatar
            alt={name}
            src={
              profilePicture
            }
          />
          <ExpandMore sx={{ color: "#000000" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleMyAccountClick}>My account</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Stack>
  );
};

export default ProfileMenu;
