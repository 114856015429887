import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Avatar,
    Grid,
    LinearProgress,
    Typography,
    TextField,
    Button,
    TableCell,
    TableRow,
    TableHead,
    TablePagination,
    Table,
    TableContainer,
    TableBody,
    CircularProgress,
    Box,
    Modal,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Card, CardContent,
    Autocomplete,
    IconButton
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CardStyles from "../../components/atoms/card/index.module.scss";
import { message } from "antd";
import PlanShowModal from "../../components/settingsComponents/planShowModal";
import ModalComponent from "../atoms/ModalComponent";
import { Add, Edit } from "@mui/icons-material";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import { updateCpoAdminAccountSettings, checkEndPointAvailability, setEndPoint } from "../../redux/apis/cpo";
import { WhoAmI } from "../../redux/apis/user";
import { PhotoCamera } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateCpoEndpoint } from "../../redux/slices/user/cpoSlice";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Elements} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cards from "./cards";
import updateCpoProfilePicture from "../../redux/slices/user/cpoSlice";
import {getCardDetails} from "../../redux/apis/cpo";
import { getInvoices } from "../../redux/apis/subscription";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY); // Replace with your Stripe publishable key


const CpoAccountSettings = () => {
    const [showPlanModal, setShowPlanModal] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        taxation: "",
        billingAddress: "",
        country: "",
        currency: "",
        language: "",
        file: null
    });
    const [invoices, setInvoices] = useState([]);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(formData.country || "");
    const [endpoint, setEndpoint] = useState(""); // Current endpoint state
    const [endpointModalOpen, setEndpointModalOpen] = useState(false); // Modal open/close state
    const [newEndpoint, setNewEndpoint] = useState(""); // State to track entered endpoint in modal
    const [validationError, setValidationError] = useState(""); // Validation error for display in the modal
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [isProfileSet, setIsProfileSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Name is required";
        }

        if (!formData.taxation) {
            newErrors.taxation = "Taxation is required";
        }

        if (!formData.billingAddress) {
            newErrors.billingAddress = "Billing address is required";
        }

        if (!formData.country) {
            newErrors.country = "Country is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }
    const dispatch = useDispatch();
    // Fetch user details using WhoAmI API
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await WhoAmI();
            setCurrentSubscription(response.subscription);
            const cpoData = response.settings.cpo;

            setFormData({
                name: cpoData.name || "",
                taxation: cpoData.taxation || "",
                billingAddress: cpoData.billingAddress || "",
                country: cpoData.country || "",
                currency: cpoData.currency || "",
                language: cpoData.language || "",
                file: cpoData.profilePicture || null
            });

            setEndpoint(cpoData.endpoint);


        } catch (error) {
            console.error("Failed to fetch user data:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        fetchData();
    }, [formData.file, endpoint, isProfileSet]);


    //Fetch Invoices
    const fetchInvoices = async () => {
        try {
            const response = await getInvoices();
            setInvoices(response.invoices);
        } catch (error) {
            console.error("Failed to fetch invoices:", error);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, []);

    // Fetch country data from the REST Countries API
    const fetchCountries = async () => {
        setLoadingCountries(true);
        try {
            const response = await fetch("https://restcountries.com/v3.1/all");
            const data = await response.json();
            setCountries(data);
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        } finally {
            setLoadingCountries(false);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);


    // Update formData with selected country details
    const handleCountryChange = (event, selectedCountry) => {
        if (!selectedCountry) return;
        const countryCode = selectedCountry.cca2;
        setSelectedCountry(selectedCountry);

        const countryCurrency = selectedCountry.currencies
            ? Object.values(selectedCountry.currencies)[0].name // Get the first currency name
            : "N/A";
        const countryLanguage = selectedCountry.languages
            ? Object.values(selectedCountry.languages)[0] // Get the first language (full name)
            : "N/A";


        // Update formData with the selected country, currency, and language
        setFormData((prevFormData) => ({
            ...prevFormData,
            country: countryCode,
            currency: countryCurrency,
            language: countryLanguage,
        }));
    };

    // Set default country on component mount based on formData.country
    useEffect(() => {
        if (formData.country && countries.length) {
            const initialCountryDetails = countries.find(
                (country) => country.cca2 === formData.country
            );
            if (initialCountryDetails) {
                setSelectedCountry(initialCountryDetails);
            }
        }
    }, [formData.country, countries]);




    useEffect(() => {
        fetchCountries();
    }, []);

    // Handle image upload
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
      
        if (!file) {
          message.error("No file selected");
          return;
        }
      
        try {
          setLoading(true);
          const updatedFormData = new FormData();
          updatedFormData.append("file", file);
      
          // Dispatch to update state with the file
      
          // Perform the API call to update the picture on the backend
          await updateCpoAdminAccountSettings(updatedFormData);
      
          fetchData(); // Refresh data after successful update
          setIsProfileSet(true);
          message.success("Profile picture updated successfully");
        } catch (error) {
          message.error(error.response.data.message);
        } finally {
          setLoading(false);
        }
      };
      

    // Handle form submit for updating account settings
    const handleUpdateAccount = async () => {
         if(!validateForm()){
            return;
        }

        setLoading(true);
        try {
            const payload = {
                ...formData,
            };
            await updateCpoAdminAccountSettings(payload);
            message.success("Account settings updated successfully");
            handleCloseEditModal();
        } catch (error) {
            console.error("Failed to update account settings:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenEditModal = () => {
        setOpenEditModal(true)
        setErrors({});
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        fetchData();
        
    };
    const handleOpenTransferModal = () => setOpenTransferModal(true);
    const handleCloseTransferModal = () => setOpenTransferModal(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    const handleTransferCPO = () => {
        setOpenTransferModal(false);
    }

    const [openDeletePortalModal, setOpenDeletePortalModal] = useState(false);

    const handleOpenDeletePortalModal = () => {
        setOpenDeletePortalModal(true);
    }

    const handleCloseDeletePortalModal = () => {
        setOpenDeletePortalModal(false);
    }

    const handleDeleteConfirmation = () => {
        setOpenDeletePortalModal(false);
    }

    // Enpoint API calls

    // Open and close the modal
    const openEndpointModal = () => setEndpointModalOpen(true);
    const closeEndpointModal = () => {
        setEndpointModalOpen(false);
        setValidationError("");
        setNewEndpoint(""); // Reset new endpoint on close
    };

    // Validate endpoint (alphanumeric and hyphens)
    const isValidEndpoint = (endpoint) => /^[a-zA-Z0-9-]+$/.test(endpoint);

    // Check if the endpoint is available via your API after validation
    const checkEndpoint = async () => {
        // Step 1: Client-side validation
        if (!isValidEndpoint(newEndpoint)) {
            setValidationError("URL Endpoint can only contain alphanumeric characters or hyphens.");
            return;
        }

        // Step 2: API check if validation passes
        setLoading(true);

        try {
            // Call your own Check Endpoint Availability API
            const response = await checkEndPointAvailability({ endpoint: newEndpoint });
            console.log(response);

            // Assuming your API returns a boolean `isAvailable` field
            if (response.isAvailable) {
                message.success("URL Endpoint is available.");
                setValidationError("");
            } else {
                setValidationError("URL Endpoint is not available. Please try a different one.");
            }
        } catch (error) {
            console.error("Error checking endpoint:", error);
            setValidationError("Failed to check the endpoint. Please try again.");
        }

        setLoading(false);
    };

    // Set the endpoint via your API
    const setEndpointHandler = async () => {
        // Same validation applied during setting the endpoint
        if (!isValidEndpoint(newEndpoint)) {
            setValidationError("Endpoint can only contain alphanumeric characters and hyphens.");
            return;
        }

        setLoading(true);

        try {
            setLoading(true);
            // Call your Set Endpoint API
            const response = await setEndPoint({ endpoint: newEndpoint });
            console.log(response);
            // Assuming your API returns the updated URL in `updateUrl`

            setEndpoint(response.endpoint); // Update the current endpoint
            message.success("Endpoint updated successfully");
            closeEndpointModal();
            dispatch(updateCpoEndpoint(response.endpoint));

            // Retrieve cpoDetails from local or session storage
            let cpoDetails = localStorage.getItem('cpoDetails')
                ? JSON.parse(localStorage.getItem('cpoDetails'))
                : sessionStorage.getItem('cpoDetails')
                    ? JSON.parse(sessionStorage.getItem('cpoDetails'))
                    : null;

            if (cpoDetails) {
                // Update the endpoint attribute
                cpoDetails.endpoint = response.endpoint;

                // Save the updated object back to local or session storage
                localStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
                // If you want to ensure you're using sessionStorage instead
                // sessionStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
            }

            // Delay the redirect to allow the spinner to show
            navigate(`/csms/${response.endpoint}/settings`);


        } catch (error) {
            console.error("Error setting endpoint:", error);
            message.error("Failed to set the endpoint. Please try again.");
        }
        finally {
            setLoading(false);
        }


    };

    //fetch cards
    const getCards = async () => {
        try {
            const response = await getCardDetails();
            setCards(response.list);
        }
        catch (error) {
            console.error("Error fetching cards:", error);
        }
    }

    useEffect(() => {
        getCards();
    }, []);

    return (
        
        <>
            {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="55vh">
                <CircularProgress />
            </Box>)
            :(<>
                <Grid
                    className={CardStyles.card}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"left"}
                >
                    <div style={{ position: "relative", display: "inline-block" }}>
                        <Avatar
                            src={
                                formData?.file instanceof File
                                    ? URL.createObjectURL(formData.file)
                                    : `${formData?.file}?t=${new Date().getTime()}`
                            }
                            className="ProfileImg"
                            sx={{
                                background: "#BDBDBD",
                                width: "69px",
                                height: "69px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "10px",
                                "& img": {
                                    objectFit: "contain",
                                    width: "100%",
                                    height: "100%",
                                },
                            }}
                        />
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton
                                component="span"
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    right: 0,
                                    background: "rgba(255, 255, 255, 0.7)",
                                    borderRadius: "50%",
                                    boxShadow: 2,
                                }}
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </div>

                    <Typography sx={{ marginLeft: "4rem" }}>
                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>CPO Name :</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.name}</Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", fontSize: "1rem", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "500" }}>Update URL:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                {"https://core.chargenex.com/csms/"}
                            </Typography>
                            <TextField
                                name="updateUrl"
                                value={endpoint}
                                variant="outlined"
                                sx={{ width: "auto", "& .MuiOutlinedInput-input": { padding: "5px 5px" } }}
                                disabled
                            />
                            <Button variant="outlined" sx={{ marginLeft: "1rem" }} onClick={openEndpointModal}>
                            Update URL
                            </Button>
                        </Typography>

                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>Taxation:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.taxation}</Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>Billing Address:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.billingAddress}</Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>Country:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.country}</Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>Currency:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.currency}</Typography>
                        </Typography>
                        <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                            <Typography sx={{ fontWeight: "500" }}>Language:</Typography>
                            <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.language}</Typography>
                        </Typography>
                    </Typography>
                    <Typography sx={{ marginLeft: "auto", display: "flex", gap: "1rem", flexDirection: "column" }} >
                        <Button onClick={handleOpenEditModal} variant="outlined">Update Account Detials</Button>
                        <Button onClick={handleOpenTransferModal} variant="outlined">Transfer Ownership</Button>
                        <Button onClick={handleOpenDeletePortalModal} variant="outlined">Delete Portal</Button>
                    </Typography>
                </Grid>
            </>)}
            <>
                <Grid xs={12} sx={{ marginTop: "1rem" }} gap={2}>
                    <Typography
                        component={"h1"}
                        fontWeight={"500"}
                        fontSize={"1.25rem"}
                        marginBottom={"0.5rem"}
                    >
                        Subscription Settings
                    </Typography>
                    <Grid display={"flex"} width={"100%"} gap={2} md={12}>
                        <Grid md={6} width={"100%"} className={CardStyles.card} >
                            <Typography
                                component={"h1"}
                                fontWeight={"500"}
                                fontSize={"14px"}
                                marginBottom={"0.75rem"}
                            >
                                Subscription Plan
                            </Typography>
                            <Grid sx={{ display: "flex", gap: 2 }}>
                                <Typography sx={{ display: "flex", minWidth: "150px", justifyContent: "center", alignItems: "center", backgroundColor: "#002F69", padding: "5px 10px", borderRadius: "8px", color: "white" }}>
                                    {currentSubscription?.planName || "Free"}
                                </Typography>
                                <Button
                                    sx={{ marginTop: "auto", color: 'white', height: '40px', maxWidth: "150px" }}
                                    variant="contained"
                                    onClick={() => setShowPlanModal(true)}
                                >Upgrade Plan</Button>
                            </Grid>
                        </Grid>

                        <Grid md={6} width={"100%"} className={CardStyles.card}>
                            <Typography
                                component={"h1"}
                                fontWeight={"500"}
                                fontSize={"14px"}
                                marginBottom={"0.75rem"}
                            >
                                Subscritpion Limit
                            </Typography>
                            <Grid xs={12} display={"flex"} gap={2} flexDirection={"row"} >
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        marginBottom={"0.5rem"}
                                    >
                                        Chargers
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscription?.allowedMaxCharger || 1}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        marginBottom={"0.5rem"}
                                    >
                                        Users
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                fontWeight: "500",
                                                padding: "10px",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscription?.allowedMaxUserAccounts || 1}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} display={"flex"} gap={2} flexDirection={"row"} >
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        my={"0.5rem"}
                                    >
                                        Evse Stations
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscription?.allowedMaxEvseStations || 1}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid md={5} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        my={"0.5rem"}
                                    >
                                        Roles
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscription?.allowedMaxRoles || 1}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }} gap={2}>
                   <Elements stripe={stripePromise}>
                        <Cards   />
                    </Elements>
                    <Grid className={CardStyles.card} md={6} >
                        <Typography
                            component={"h1"}
                            fontWeight={"500"}
                            fontSize={"1.25rem"}
                            marginBottom={"0.75rem"}
                        >
                            Invoices
                        </Typography>
                        <TableContainer component={Box} sx={{height:"200px", overflow: 'auto', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Sr. No.</TableCell>
                                        <TableCell align="center">Invoice Number</TableCell>
                                        <TableCell align="center">Download</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices.map((invoice, index) => (
                                        <TableRow key={invoice.id}>
                                            <TableCell align="center">{index + 1}</TableCell>
                                            <TableCell align="center">{invoice.invoiceNumber}</TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    component="a"
                                                    href={invoice.pdfUrl}
                                                    download
                                                    target="_blank"
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: 'primary.main',
                                                        cursor: 'pointer',
                                                        padding: '4px 8px',
                                                        border: '1px solid #3f51b5',
                                                        borderRadius: '4px',
                                                        '&:hover': { backgroundColor: '#f0f0f0' }
                                                    }}
                                                >
                                                    <PictureAsPdfIcon sx={{ fontSize: '20px', verticalAlign: 'middle', marginRight: 0.5 }} />
                                                    Download
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Grid>
                </Grid>

            </>
            {showPlanModal && (
                <PlanShowModal
                    showPlanModal={showPlanModal}
                    setShowPlanModal={setShowPlanModal}
                />
            )}

          

            {/* Modal for setting endpoint using your custom ModalComponent */}
            <ModalComponent
                open={endpointModalOpen}
                handleClose={closeEndpointModal}
                showSubmitButton={true}
                headerText="Update URL"
                submitButtonLabel="Update URL"
                handleSubmit={setEndpointHandler} // Use setEndpointHandler to submit when the modal's submit button is clicked
            >
                <Box>
                    <TextField
                        label="Set URL"
                        value={newEndpoint}
                        onChange={(e) => setNewEndpoint(e.target.value)}
                        fullWidth
                        sx={{ marginTop: "1rem" }}
                    />
                    <Button
                        variant="outlined"
                        onClick={checkEndpoint}
                        sx={{ marginTop: "1rem" }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : "Check Availability"}
                    </Button>
                    {validationError && (
                        <Typography color="error" sx={{ marginTop: "1rem" }}>
                            {validationError}
                        </Typography>
                    )}
                </Box>
            </ModalComponent>

            <ModalComponent
                open={openEditModal}
                handleClose={handleCloseEditModal}
                headerText={"Edit CPO Details"}
                handleSubmit={handleUpdateAccount}
                submitButtonLabel={"Update"}
            >
                <form>
                    <TextField
                        name="name"
                        label="CPO Name"
                        value={formData?.name}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        name="taxation"
                        label="Taxation"
                        value={formData?.taxation}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.taxation}
                        helperText={errors.taxation}
                    />
                    <TextField
                        name="billingAddress"
                        label="Billing Address"
                        value={formData?.billingAddress}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.billingAddress}
                        helperText={errors.billingAddress}
                    />

                    {/* Autocomplete Country Dropdown */}
                    <Autocomplete
                        options={countries}
                        getOptionLabel={(country) => country?.name?.common || ""}
                        value={selectedCountry}
                        loading={loadingCountries}
                        onChange={handleCountryChange}
                        isOptionEqualToValue={(option, value) =>
                            option.cca2 === value.cca2
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Country"
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                error={errors.country}
                                helperText={errors.country}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loadingCountries ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />

                    {/* Conditionally render Currency and Language fields */}
                    {selectedCountry && (
                        <>
                            <TextField
                                name="currency"
                                label="Currency"
                                value={formData.currency || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                name="language"
                                label="Language"
                                value={formData.language || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>
                    )}
                </form>
            </ModalComponent>

            <Dialog open={openDeletePortalModal} onClose={handleCloseDeletePortalModal}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Portal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeletePortalModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CpoAccountSettings;