import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
    TablePagination,
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
} from "@mui/material";
import ModalComponent from "../atoms/ModalComponent";
import { Add, Edit, Search, Delete } from "@mui/icons-material";
import ButtonComponent from "../atoms/button/button";

function UserManagementContainer() {
    const [addSiteHostModalOpen, setAddSiteHostModalOpen] = useState(false);
    const [editUserModalOpen, setEditUserModalOpen] = useState(false); // New state for edit modal
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [role, setRole] = useState("");
    const [selectedUser, setSelectedUser] = useState(null); // New state for selected user

    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openEditUserModal = (user) => {
        setSelectedUser(user);
        setEditUserModalOpen(true);
    };

    const usersData = [
        {
            sr_no: 1,
            firstName: "Abhijeet",
            lastName: "Jadhav",
            email: "admin@chargnex.com",
            phoneNumber: "9332814567",
            userRole: "Super Admin",
            createdAt: "2024-02-01T19:48:22",
        },
        {
            sr_no: 2,
            firstName: "Akshay",
            lastName: "Atkar",
            email: "akshayatkar@chargnex.com",
            phoneNumber: "8337677876",
            userRole: "Admin",
            createdAt: "2024-02-02T19:48:22",
        },
        {
            sr_no: 3,
            firstName: "Aniket",
            lastName: "Waje",
            email: "aniketwaje@chargnex.com",
            phoneNumber: "8722766345",
            userRole: "Customer First Point of Contact",
            createdAt: "2024-02-02T19:48:23",
        },
        {
            sr_no: 4,
            firstName: "Shivam",
            lastName: "Narkhede",
            email: "shivam@chargnex.com",  
            phoneNumber: "7833722876",
            userRole: "Ops Manager",
            createdAt: "2024-02-05T00:32:09",
        },
    ];

    const rolesData = [
        "Super Admin",
        "Admin",
        "Domain Expert",
        "Customer First Point of Contact",
    ];

    return (
        <>
            <Typography
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}

            >
                <Typography sx={{ display: "flex", flexDirection: "column"}}>
                    <TextField
                        id="s"
                        variant="outlined"
                        hiddenLabel
                        placeholder="search"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Search />
                                </InputAdornment>
                            ),
                            sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
                        }}
                        InputLabelProps={{ sx: { fontSize: "14px" } }}
                    />
                    <Typography component={"span"} sx={{ marginTop: "10px" }}>
                        <Typography component={"b"}>Super Admin </Typography>{" : Abhijeet Jadhav"}
                    </Typography>
                </Typography>
                <Typography>
                    <ButtonComponent
                        label={
                            <>
                                <Add /> <Typography component={"span"}>ADD User</Typography>
                            </>
                        }
                        width={"200px"}
                        height={"40px"}
                        onClick={() => setAddSiteHostModalOpen(true)}
                    />
                </Typography>
            </Typography>
            <TableContainer sx={{marginTop: "20px"}}>
                <Table
                    sx={{ minWidth: 650, textTransform: "capitalize" }}
                    aria-label="simple table"
                >
                    <TableHead
                        sx={{
                            "& td, & th": { border: "1px solid #4C4E641F !important" },
                            background: "#F5F3F3 0% 0% no-repeat padding-box",
                        }}
                    >
                        <TableRow>
                            <TableCell align="center">Sr No.</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Email ID</TableCell>
                            <TableCell align="center">Mobile Number</TableCell>
                            <TableCell align="center">Role</TableCell>
                            <TableCell align="center">Date Of Registraion</TableCell>
                            <TableCell align="center">Created By</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersData.map((row, index) => (
                            <TableRow
                                key={row.sr_no}
                                sx={{
                                    "& td, & th": { border: "1px solid #4C4E641F !important" },
                                }}
                            >
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">
                                    {row.firstName + " " + row.lastName}
                                </TableCell>
                                <TableCell align="center">{row.email}</TableCell>
                                <TableCell align="center">{row.phoneNumber}</TableCell>
                                <TableCell align="center">{row.userRole}</TableCell>
                                <TableCell align="center">
                                    {new Date(row.createdAt).toLocaleString()}
                                </TableCell>
                                <TableCell align="center">Aagam Jain</TableCell>
                                <TableCell align="center">
                                    <Typography>
                                        <Edit
                                            onClick={() => openEditUserModal(row)} // Open edit modal on click
                                            sx={{ color: "#6A6A6A" }}
                                        />
                                    </Typography>
                                    <Typography>
                                        <Delete onClick={() => { }} sx={{ color: "#6A6A6A" }} />
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    count={users.length}
                    component="div"
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            {/* Add User Modal */}
            {addSiteHostModalOpen && (
                <ModalComponent
                    open={addSiteHostModalOpen}
                    submitButtonLabel={"Add User"}
                    headerText={"Portal User Registration"}
                    handleClose={() => setAddSiteHostModalOpen(false)}
                >
                    <TextField
                        required
                        id="outlined-required"
                        label="User Name"
                        defaultValue=""
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Email ID"
                        defaultValue=""
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Mobile No."
                        defaultValue=""
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        id="outlined-role"
                        label="Role"
                        value={role}
                        onChange={handleRoleChange}
                        select
                        fullWidth
                        margin="normal"
                    >
                        {rolesData.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </TextField>
                </ModalComponent>
            )}

            {/* Edit User Modal */}
            {editUserModalOpen && selectedUser && (
                <ModalComponent
                    open={editUserModalOpen}
                    submitButtonLabel={"Update User"}
                    headerText={"Edit User Details"}
                    handleClose={() => setEditUserModalOpen(false)}
                >
                    <TextField
                        required
                        id="outlined-required"
                        label="User Name"
                        defaultValue={selectedUser.firstName + " " + selectedUser.lastName}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Email ID"
                        defaultValue={selectedUser.email}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Mobile No."
                        defaultValue={selectedUser.phoneNumber}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        id="outlined-role"
                        label="Role"
                        value={role}
                        onChange={handleRoleChange}
                        select
                        fullWidth
                        margin="normal"
                    >
                        {rolesData.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </TextField>
                </ModalComponent>
            )}
        </>
    );
}

export default UserManagementContainer;
