import React from "react";
import CardStyles from "../../components/atoms/card/index.module.scss";
import {
    Grid,
    Typography,
    Avatar,
} from "@mui/material";

const SuperAdminMyProfile = () => {
     const userDetails = JSON.parse(localStorage.getItem("userDetails")) || JSON.parse(sessionStorage.getItem("userDetails"));


    return (
        <>

            <Grid
                className={CardStyles.card}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"left"}
            >
                <Avatar
                    alt={userDetails.firstName}
                    src="../icons/chargenxLogo.svg"
                    className="ProfileImg"
                    sx={{
                        background: "#BDBDBD",
                        width: "69px",
                        height: "69px",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '10px',
                        '& img': {
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                        }
                    }}
                />
                <Typography
                    sx={{
                        marginLeft: "3rem",
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4rem",
                    }}
                >
                    <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                        <Typography sx={{ fontWeight: "500" }}>Name :</Typography>
                        <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                            {`Abhijeet Jadhav`}
                        </Typography>
                    </Typography>
                    <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                        <Typography sx={{ fontWeight: "500" }}>Email :</Typography>
                        <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                            {userDetails.email}
                        </Typography>
                    </Typography>
                    <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                        <Typography sx={{ fontWeight: "500" }}>Mobile :</Typography>
                        <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                            {"9332814567"}
                        </Typography>
                    </Typography>
                </Typography>
                <Typography
                    display={"flex"}
                    flexDirection={"column"}
                    marginLeft={"4rem"}
                    alignItems={"flex-start"}
                    height={"71px"}
                >
                    <Typography display={"flex"}>
                        <Typography sx={{ fontWeight: "500" }}>Role :</Typography>
                        <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                            {"Super Admin"}
                        </Typography>
                    </Typography>
                </Typography>
            </Grid>

             {/* Permissions */}

             <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }}>
                <Grid className={CardStyles.card} xs={12}>
                    <Typography
                        component={"h1"}
                        fontWeight={"500"}
                        fontSize={"1.25rem"}
                        marginBottom={"0.5rem"}
                    >
                        Permissions
                    </Typography>
                    <Grid sx={{ display: "flex" }}>
                        <Grid
                            sx={{
                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                borderRadius: "14px",
                                opacity: "1",
                                alignItems: "center",
                                padding: "1rem",
                                fontSize: "1rem",
                                display: "flex",
                            }}
                            xs={12}
                            container
                        >
                            <Grid xs={6}>CPO</Grid>
                            <Grid xs={6}>EVSE Station</Grid>
                            <Grid xs={6}>Chargers</Grid>
                            <Grid xs={6}>Analytics</Grid>
                            <Grid xs={6}>Device Management</Grid>
                            <Grid xs={6}>User Management</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default SuperAdminMyProfile;