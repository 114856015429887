import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { Margin, Menu } from "@mui/icons-material";
import ProfileMenu from "../atoms/profileMenu";
import { useNavigate, useLocation } from "react-router";
import QuickStartGuide from "../quick-start/quickStart";
import {Close} from '@mui/icons-material';
import { WhoAmI } from "../../redux/apis/user";
import { useSelector } from "react-redux";


const MainHeader = ({ toggleSidebar, isMobile }) => {
  const [openQuickStart, setOpenQuickStart] = useState(false);

  const handleClickOpen = () => {
    setOpenQuickStart(true);
  };

  const handleClose = () => {
    setOpenQuickStart(false);
  };

  const myProfilePicture = useSelector((state) => state.cpo.myProfilePicture);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
  // Clear session storage
  sessionStorage.clear();
  localStorage.removeItem("userDetails");
  sessionStorage.removeItem("userDetails");
  localStorage.removeItem("cpoDetails");
  sessionStorage.removeItem("cpoDetails");

    const currentUserId = localStorage.getItem("currentUser");
    if (currentUserId) {
      localStorage.removeItem(`user_${currentUserId}`);
      localStorage.removeItem(`token_${currentUserId}`);
      localStorage.removeItem(`rememberMe_${currentUserId}`);
      localStorage.removeItem("currentUser");
    }

    navigate("/login");
  };

  const [userData, setuserData] = useState({
    file: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    category: "",
  });
  // Directly initialize userData and permissions using WhoAmI response
  useEffect(() => {
    WhoAmI()
      .then((response) => {
        const userDetails = response || {};

        setuserData({
          file: userDetails?.user?.profilePicture || "",
          firstName: userDetails?.user.firstName || "",
          lastName: userDetails?.user.lastName || "",
          email: userDetails?.user.email || "",
          phoneNumber: userDetails?.user.phoneNumber || "N/A",
          category: userDetails?.userRole || "",
        });

      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, [myProfilePicture]);

  const cpoDetails = localStorage.getItem('cpoDetails') && JSON.parse(localStorage.getItem('cpoDetails')); // Extract CPO details
  const cpoEndpoint = cpoDetails?.endpoint ? cpoDetails?.endpoint : 'cpo';

  const HeadingMapper = () => {
    switch (location?.pathname) {
      case "/home":
        return "Home";
      case "/chargers":
        return "Chargers";
      case "/evse-stations":
        return "EVSE Stations";
      case "/device-management/monitor":
        return "Monitor";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/site-host-operator":
        return "Site Host Operators";
      case "/cpo":
          return "CPO";
      case "/evse-owners":
        return "EVSE Owners";
      case "/analytics":
        return "Analytics";
      case "/settings":
        return "Settings";
      case `/csms/${cpoEndpoint}/home`:
        return "Home";
      case `/csms/${cpoEndpoint}/evse-stations`:
        return "EVSE Stations";
      case `/csms/${cpoEndpoint}/chargers`:
        return "Chargers";
      case `/csms/${cpoEndpoint}/customers`:
        return "Customers";
      case `/csms/${cpoEndpoint}/sessions`:
        return "Sessions";
      case `/csms/${cpoEndpoint}/analytics`:
        return "Analytics";
      case `/csms/${cpoEndpoint}/integration`:
        return "Integrations";
      case `/csms/${cpoEndpoint}/settings`:
        return "Settings";
      
    }
  };
  return (
    <>
    <AppBar position="static" sx={{ background: "#fff",color: "#000000", boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)", opacity: 1}}>
      <Toolbar sx={{ overflowX: "hidden" }}>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleSidebar}
          >
            <Menu />
          </IconButton>
        )}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          fontSize={"19px"}
        >
          {HeadingMapper()}
        </Typography>
        <ProfileMenu
          name={userData.firstName+" "+userData.lastName}
          onLogout={handleLogout}
          onMyAccountClick={() => {
            {userData.category === "CPO Admin" ? navigate(`/csms/${cpoEndpoint}/settings`) : navigate("/settings")}
          }}
          role={(userData.category === "Super Admin") && "Super Admin" || (userData.category === "CPO Admin") && "CPO Admin" || (userData.category === "factory_manager") && "Factory Manager"}
          
         
        />
      </Toolbar>

    </AppBar>

    {/* QuickStartGuide Modal */}
      <Dialog open={openQuickStart} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Quick Start Guide</DialogTitle>
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }} aria-label="close" color="primary" size="large">
        <Close />
      </IconButton>
        <DialogContent>
          <QuickStartGuide  onClose={handleClose} openQuickStartModal={handleClickOpen} />
        </DialogContent>
      </Dialog>

      </>
  );
};

export default MainHeader;
