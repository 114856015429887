import React from "react";
import CardStyles from "../../components/atoms/card/index.module.scss";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonComponent from "../atoms/button/button";
import { useState } from "react";
import ModalComponent from "../atoms/ModalComponent"; // Import ModalComponent

const Roles = () => {
  const [open, setOpen] = useState(false);
  const [openAddRole, setOpenAddRole] = useState(false);
  const [openEditRole, setOpenEditRole] = useState(false); // State for editing role
  const [selectedRole, setSelectedRole] = useState("");
  const [customRole, setCustomRole] = useState(""); 
  const [permissions, setPermissions] = useState({
    evseStation: false,
    chargers: false,
    analytics: false,
  });

  // Define the map of roles and their respective permissions
  const rolesPermissionsMap = {
    "Super Admin": {
      evseStation: true,
      chargers: true,
      analytics: true,
      
    },
    "Admin": {
      evseStation: true,
      chargers: true,
      analytics: false,
    },
    "Coordinator": {
      evseStation: false,
      chargers: true,
      analytics: true,
    },
    "Factory Manager": {
      evseStation: false,
      chargers: true,
      analytics: true,
    },
    "Customer First Point of Contact": {
      evseStation: false,
      chargers: true,
      analytics: false,
    },
    "Troubleshooting Engineers": {
      evseStation: false,
      chargers: true,
      analytics: false,
    },
    "Domain Experts": {    
      evseStation: false,
      chargers: true,
      analytics: true,
    },
    "Technical Assistants": {
      evseStation: false,
      chargers: true,
      analytics: true,
    }
  };

  const permissionList = [
    { name: "evseStation", label: "EVSE Station" },
    { name: "chargers", label: "Chargers" },
    { name: "analytics", label: "Analytics" },
  ];

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    // Set permissions based on the role selected
    setPermissions(rolesPermissionsMap[role] || {});
    setOpen(true);
  };

  const handleEditClick = (role) => {
    setSelectedRole(role);
    // Set permissions based on the role selected
    setPermissions(rolesPermissionsMap[role] || {});
    setOpenEditRole(true);
  };

  const handleDeleteClick = (role) => {
    console.log("Delete clicked for:", role);
  };

  const handlePermissionChange = (event) => {
    setPermissions({
      ...permissions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => setOpen(false);
  const handleAddRoleClose = () => setOpenAddRole(false);
  const handleEditRoleClose = () => setOpenEditRole(false);

  const handleAddRoleClick = () => {
    setPermissions({
      evseStation: false,
      chargers: false,
      customers: false,
      analytics: false,
      integration: false,
    });
    setOpenAddRole(true);
  };

  const handleSaveRole = () => {
    if (customRole) {
      console.log("New Role:", customRole);
      console.log("Permissions:", permissions);
    }
    handleAddRoleClose();
  };

  return (
    <>
      <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }}>
        <Grid className={CardStyles.card} xs={12}>
          <Grid
            sx={{ display: "flex", justifyContent: "space-between", marginBottom: "1rem" }}
          >
            <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"}>
              Roles
            </Typography>
            <ButtonComponent
              sx={{ height: "40px", width: "80px" }}
              onClick={handleAddRoleClick}
              label={"Add Role"}
              variant="contained"
            />
          </Grid>
          <TableContainer sx={{
              maxHeight: "55vh",
              overflow: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
            >
            <Table>
              <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}
              >
                <TableRow
                sx={{
                    "& td, & th": {
                      border: "1px solid #4C4E641F !important",
                    },
                  }}>

                  <TableCell align="center">Sr No.</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
              sx={{
                "& td": {
                  border: "1px solid #e0e0e0",
                  color: "#555",
                },
                "& tr:nth-of-type(odd)": {
                  backgroundColor: "#fafafa",
                },
                "& tr:hover": {
                  backgroundColor: "#f1f1f1",
                },
              }}
              >
                {Object.keys(rolesPermissionsMap).map((role, index) => (
                  <TableRow key={role}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                      onClick={() => handleRoleClick(role)}
                    >
                      {role}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleEditClick(role)}>
                        <EditIcon />
                      </IconButton>
                      {(role === "Manager" || role === "Assistant Manager") && (
                          <IconButton onClick={() => handleDeleteClick(role)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Permissions Modal */}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{selectedRole} Permissions</DialogTitle>
            <DialogContent>
              {permissionList.map((permission) => (
                <FormControlLabel
                  key={permission.name}
                  control={
                    <Checkbox
                      checked={permissions[permission.name]}
                      name={permission.name}
                      disabled // Make checkboxes non-editable
                    />
                  }
                  label={permission.label}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Edit Role Modal using ModalComponent */}
          {openEditRole && (
            <ModalComponent
              open={openEditRole}
              submitButtonLabel={"Save Changes"}
              headerText={"Edit Role"}
              handleClose={handleEditRoleClose}
            >
              <TextField
                required
                label="Role Name"
                defaultValue={selectedRole}
                fullWidth
                margin="normal"
              />
              <Typography variant="h6" marginTop="1rem">
                Permissions
              </Typography>
              {permissionList.map((permission) => (
                <FormControlLabel
                  key={permission.name}
                  control={
                    <Checkbox
                      checked={permissions[permission.name]}
                      onChange={handlePermissionChange}
                      name={permission.name}
                    />
                  }
                  label={permission.label}
                />
              ))}
            </ModalComponent>
          )}

          {/* Add Role Modal */}
          <Dialog open={openAddRole} onClose={handleAddRoleClose}>
            <DialogTitle>Add New Role</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Role Name"
                value={customRole}
                onChange={(e) => setCustomRole(e.target.value)}
                margin="normal"
              />
              <Typography variant="h6" marginTop="1rem">
                Permissions
              </Typography>
              {permissionList.map((permission) => (
                <FormControlLabel
                  key={permission.name}
                  control={
                    <Checkbox
                      checked={permissions[permission.name]}
                      onChange={handlePermissionChange}
                      name={permission.name}
                    />
                  }
                  label={permission.label}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddRoleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSaveRole} color="primary">
                Save Role
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  );
};

export default Roles;
