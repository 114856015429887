import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthLayout from '../../layout/authLayout';
import ChargnexLogo from '../../components/icons/chargnexLogo';
import ChargnexBackgroundLogo from '../../components/icons/chargnexBackgroundLogo';
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/VpnKey';
import axios from 'axios';
import { InputAdornment } from '@mui/material';
import { WhoAmI } from '../../redux/apis/user';

const ResetPassword = () => {
  const location = useLocation();
  const { state } = location;
  
  // Fetch email and rememberMe from location state if available
  const checkEmail = state ? state.email : "";
  const rememberMeFromLocation = state ? state.rememberMe : false;

  const [email, setEmail] = useState(checkEmail);
  const [passcode, setPasscode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const [cpoEndpoint, setCpoEndpoint] = useState("cpo")

  // Effect to set email and rememberMe state based on location state
  useEffect(() => {
    if (checkEmail) {
      setEmail(checkEmail);
    }
  }, [checkEmail]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasscodeChange = (event) => {
    setPasscode(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setMessage(''); // Reset any previous messages

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      // Reset password API call
      const payload = {
        code: passcode,
        email, // Include the email in the payload
        newPassword,
        confirmPassword,
      };

      const response = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/auth/reset-password`, payload);
      
      // After successful password reset, attempt to log the user in
      const { user, session, userRole } = response.data;
      const userDetails = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.phoneNumber,
        category: userRole,
      };

      const cpoDetails = response.data?.settings?.cpo;
      setCpoEndpoint(cpoDetails?.endpoint);

      // Save user and session data in local or session storage based on rememberMe
      if (rememberMeFromLocation) {
        localStorage.setItem('currentUser', user.id);
        localStorage.setItem(`user_${user.id}`, user.id);
        localStorage.setItem(`token_${user.id}`, session.token);
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        localStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
      } else {
        sessionStorage.setItem('currentUser', user.id);
        sessionStorage.setItem(`user_${user.id}`, user.id);
        sessionStorage.setItem(`token_${user.id}`, session.token);
        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
        sessionStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
      }

      // Redirect based on user category
      if (userDetails.category === "CPO Admin") {
        if (cpoEndpoint === null || cpoEndpoint === undefined || cpoEndpoint === "cpo") {
          // Redirect to settings if endpoint is null or undefined
          window.location.href = `/csms/${cpoEndpoint || "cpo"}/settings`;
          return; // Exit early after redirect
        } else {
          // Redirect to home if endpoint is not null
          window.location.href = `/csms/${cpoEndpoint || "cpo"}/home`;
          return;
        }
      } else if (userDetails.category === "factory_manager") {
        navigate("/home");
      } else {
        navigate("/home");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <AuthLayout>
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        sx={{
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#002f69",
            borderRadius: { md: "19px 0px 0px 19px", xs: "19px 19px 0px 0px" },
            padding: "40px",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ChargnexLogo width={undefined} height={undefined} />
          <Typography component="div" sx={{ position: "absolute", bottom: 0 }}>
            <ChargnexBackgroundLogo />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: "40px",
            borderRadius: "19px",
            backgroundColor: "#ffffff",
            maxWidth: "400px",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            fontWeight="500"
            fontSize="30px"
            marginBottom="20px"
            textAlign="center"
          >
            Reset Password
          </Typography>
          <form onSubmit={onSubmit} noValidate>
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="passcode"
              label="Passcode"
              name="passcode"
              value={passcode}
              onChange={handlePasscodeChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="new-password"
              label="New Password"
              name="newPassword"
              type="password"
              value={newPassword}
              onChange={handleNewPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              sx={{ marginBottom: "20px" }}
              id="confirm-password"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                marginBottom: "20px",
                color: "white",
                borderRadius: "14px",
                height: "48px",
              }}
            >
              Reset Password
            </Button>
            {message && <Typography marginTop="20px" color="red">{message}</Typography>}
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default ResetPassword;
