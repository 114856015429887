import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, responsiveFontSizes , createTheme } from "@mui/material/styles";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard";
import SiteHosts from "./pages/siteHosts/siteHosts";
import Chargers from "./pages/chargers/chargers";
import ChargersCpo from "./pages/chargers/chargersCpo";
import Analytics from "./pages/analytics/analytics";
import Monitors from "./pages/monitor/monitors";
import OtaUpdates from "./pages/otaUpdates/otaUpdates";
import Settings from "./pages/settings/settings";
import EsevOwners from "./pages/evseOwners/evseOwners";
import ForgotPassword from "./pages/login/forgotPassword";
import ResetPassword from "./pages/login/resetPassword";
import EsevStations from "./pages/evseStations/evseStation";
import EsevStationsCpo from "./pages/evseStations/evseStationCpo";
import Support from "./pages/support/support";
import CheckLogin from "./pages/login/confimLogin";
import CpoAdmin from "./pages/CpoAdmin/cpoAdmin";
import SiteHostOperator from "./pages/siteHostOperators/siteHostOperator";
import ProtectedRoute from "./components/protection/ProtectedRouteComp";
import Customers from "./pages/customers/customers";
import "./styles/app.scss"
import Integrations from "./pages/Integrations/integration";
import CpoSessions from "./pages/sessions/cpoSessions";
import Pusher from "pusher-js";
import { Dialog, DialogTitle, DialogContent, Typography, Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from 'qrcode.react';
import Barcode from "react-barcode";
import { Print } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // For copy icon
import { message } from "antd";
import { WhoAmI } from "./redux/apis/user";
import { useSelector } from "react-redux";
import { updateCpoEndpoint } from "./redux/slices/user/cpoSlice";
import { useDispatch } from "react-redux";


const App = () => {

  const userDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const category = userDetails && JSON.parse(userDetails).category;
  const [open, setOpen] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const barcodeRef = useRef(null); // Reference to the barcode content
  const qrCodeRef = useRef(null); // Reference to the QR code content
  const dispatch = useDispatch();
const fetchUsers = async () => {
  try{
    const response = await WhoAmI();
    dispatch(updateCpoEndpoint(response?.settings?.cpo?.endpoint || "cpo"));
  }
  catch(error){
    console.log(error);
  }
};

useEffect(() => {
  fetchUsers();
}, []);

const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";

const userRole = userDetails && JSON.parse(userDetails).category;

  
  // Setup the print functions using useReactToPrint
  const handlePrintBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handlePrintQRCode = useReactToPrint({
    content: () => qrCodeRef.current,
  });

  const formatSerialNumber = (serialNumber) => {
    return `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
  };
 
  useEffect(() => {
    // Initialize Pusher
    if (userRole === "Super Admin" || userRole === "factory_manager") {
      console.log("Pusher initialized");
      const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });

      // Subscribe to the channel
    const channel = pusher.subscribe('GenerateSerialNumberFromCharger');

    // Bind to the event
    channel.bind('SerialNumberGenerated', (data) => {
      setSerialNumber(data.serialNumber);
       setOpen(true);
    });

    // Clean up subscription on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
    }
  }, [window.location.pathname]);

  const handleClose = () => {
    setOpen(false); // Close the popup
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success('Copied to clipboard');
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#23CB10",
      },
      secondary: {
        main: "#002F69",
      },
      background: {
        default: "#f4f4f4", // Light background color
        paper: "#ffffff", // White background for paper elements
      },
      text: {
        primary: "#333333", // Dark text for readability
        secondary: "#555555", // Lighter text for secondary information
      },
    },
    typography: {
      fontFamily: ["poppins"],
      fontSize: 12,
      h1: {
        fontSize: "1rem", 
        fontWeight: 600,
      },
      h2: {
        fontSize: "0.7rem", 
        fontWeight: 600,
      },
      span: {
        fontSize: "12px", 
      },
      p: {
        fontSize: "12px", 
      },
      div: {
        fontSize: "12px",
      }
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            fontSize: "12px", 
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            transform: "scale(0.8)", // Scaling down the card size
            transformOrigin: "top left", // Ensuring the scaling starts from the top left corner
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: "1rem", 
            fontWeight: 600,
          },
          h2: {
            fontSize: "0.7rem", 
            fontWeight: 500,
          },
          // Add more typography styles as needed
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            padding: "10px 5px",  // Padding for header cells
          },
          body: {
            padding: "10px 5px",  // Padding for body cells
          }
        }
      }
    },
  });
  
  // Optional: Add responsive font scaling
  theme = responsiveFontSizes(theme, { factor: 2.5 });

 


  return (
    <ThemeProvider theme={theme}>
     
        <BrowserRouter>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/check-login" element={<CheckLogin />} />
            <Route path="/set-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/" element={<Navigate to="/check-login" />} />
            {/* Protected Routes */}
            {category === "CPO Admin" || category === "CPO Admin" ? (
              <>
              <Route
                path={`/csms/${cpoEndpoint}/home`}
                element={<ProtectedRoute component={Dashboard} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/evse-stations`}
                element={<ProtectedRoute component={EsevStationsCpo} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/customers`}
                element={<ProtectedRoute component={Customers} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/chargers`}
                element={<ProtectedRoute component={ChargersCpo} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/sessions`}
                element={<ProtectedRoute component={CpoSessions} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/integration`}
                element={<ProtectedRoute component={Integrations} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/analytics`}
                element={<ProtectedRoute component={Analytics} requiredRoles={["CPO Admin"]} />}
              />
      
              <Route
                path={`/csms/${cpoEndpoint}/settings`}
                element={<ProtectedRoute component={Settings} requiredRoles={["CPO Admin"]} />}
              />
              <Route
                path={`/csms/${cpoEndpoint}/support`}
                element={<ProtectedRoute component={Support} requiredRoles={["CPO Admin"]} />}
          />
              </>
            ) : (
              <>
               <Route
              path="/home"
              element={<ProtectedRoute component={Dashboard} requiredRoles={["Super Admin", "factory_manager", "site_host_operators"]} />}
            />
            <Route
            path="/site-hosts"
            element={<ProtectedRoute component={SiteHosts} requiredRoles={["Super Admin", "CPO Admin"]} />}
          />
          <Route
            path="/evse-owners"
            element={<ProtectedRoute component={EsevOwners} requiredRoles={["Super Admin", "factory_manager"]} />}
          />
          <Route
            path="/site-host-operator"
            element={<ProtectedRoute component={SiteHostOperator} requiredRoles={["Super Admin", "site_host_operators"]} />}
          />
          <Route
            path="/cpo"
            element={<ProtectedRoute component={CpoAdmin} requiredRoles={["Super Admin", "CPO Admin"]} />}
          />
          <Route
            path="/evse-stations"
            element={<ProtectedRoute component={EsevStations} requiredRoles={["Super Admin", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/chargers"
            element={<ProtectedRoute component={Chargers} requiredRoles={["Super Admin", "factory_manager", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/analytics"
            element={<ProtectedRoute component={Analytics} requiredRoles={["Super Admin", "factory_manager", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/device-management/monitor"
            element={<ProtectedRoute component={Monitors} requiredRoles={["Super Admin", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/device-management/ota-updates"
            element={<ProtectedRoute component={OtaUpdates} requiredRoles={["Super Admin", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/settings"
            element={<ProtectedRoute component={Settings} requiredRoles={["Super Admin", "factory_manager", "CPO Admin", "site_host_operators"]} />}
          />
          <Route
            path="/support"
            element={<ProtectedRoute component={Support} requiredRoles={["Super Admin", "factory_manager", "CPO Admin", "site_host_operators"]} />}
          />
              </>
            )}
          </Routes>
        </BrowserRouter>
      <>
      {/* MUI Dialog for showing the serial number */}
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          Serial Number Generated!
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center" mt={2}>
          <Typography sx={{mb: 2}}>Serial Number: <Box
                  component="span"
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    backgroundColor: '#f5f5f5',
                    textOverflow: "auto",
                    p: 1,
                    mx: 1, // Horizontal margin to space out from surrounding text
                  }}
                >
                  {formatSerialNumber(serialNumber)}</Box>
                  <IconButton onClick={() => handleCopyToClipboard(serialNumber)}><ContentCopyIcon /></IconButton></Typography>
             {/* Barcode Section */}
             <div>
              <div
                ref={barcodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="barcodeContainer">
                  <Barcode
                    value={serialNumber}
                    className="barcodeSvg"
                  />
                </div>
              </div>
              <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <Button
                variant="contained"
                style={{ color: "white", gap: "0.5rem", marginTop: "10px", maxHeight: "40px" }}
                color="primary"
                onClick={handlePrintBarcode}
              >
                <Print style={{ color: "#fff" }} />
                Print Barcode
              </Button>
              </Typography>
            </div>
            {/* QR Code Section */}
            <div>
              <div
                ref={qrCodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="qrCodeContainer">
                  <QRCodeSVG
                    value={serialNumber}
                    size={128}
                    level="H"
                    includeMargin={true}
                  />
                </div>
              </div>
              <Button
                variant="contained"
                style={{ color: "white", gap: "0.5rem" , maxHeight: "40px"}}
                color="primary"
                onClick={handlePrintQRCode}
              >
                <Print style={{ color: "#fff" }} />
                Print QR Code
              </Button>
            </div>
        </Box>
        </DialogContent>
      </Dialog>
    </>

    </ThemeProvider>
  );
};

export default App;
